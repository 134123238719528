import { css, html, LitElement } from "lit";
import { customElement } from "lit/decorators/custom-element.js";
import { property } from "lit/decorators/property.js";
import { query } from "lit/decorators/query.js";
import { stopEvent } from "../../util/util";
import { ifDefined } from "lit/directives/if-defined.js";
import {goTo} from "../../router";

@customElement("mb-seo-link")
export class MbSEOLink extends LitElement {
	@property({ type: String }) href!: string;
	@property({ type: String }) target?: "_blank" | "_self" | "_parent" | "_top" | "framename";
	@property({ type: Boolean, attribute: "no-routing" }) noRouting: boolean = false;
	@query("#anchor") anchor!: HTMLAnchorElement;

	static get styles() {
		return [
			css`
				a {
					all: unset;
					display: contents;
				}
			`
		];
	}

	handleClick(e: Event) {
		// If the link is inactive, do nothing.
		// Then it is just for SEO and the routing will be handled at another place.
		if (this.noRouting) {
			e.preventDefault();
			return;
		}

		const href = this.anchor.href;

		// If it is a relative link, use webapp routing instead
		if (href.startsWith(location.origin) && this.anchor.target !== "_blank") {
			goTo(href);
			stopEvent(e);
		}
	}

	render() {
		return html`<a id="anchor" target="${ifDefined(this.target)}" href="${this.href}" @click="${this.handleClick}"
			><slot></slot
		></a>`;
	}
}
