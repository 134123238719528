import {BlogPost} from "../types/blog-post";


// File starts with metadata in the following form
/*---
title: Hello World
description: My first post!
created: 25/05/2023
---*/
export function extractMetadata (markdown: string) {
    return {
        metadata: markdown.match(/---\n(.*?)\n---/s)?.[1],
        markdown: markdown.replace(/---\n(.*?)\n---/s, "")
    };
}

export function cleanMarkdown (markdown: string): string {
    // Remove links eg. [Filmstriben](https:google.com/rstrst)
    return markdown.replaceAll(/\[(.+)\]\(.+\)/sg, "$1")
}

export function parseMarkdownMetadata ({metadata, slug}: { metadata: string; slug: string }): BlogPost | null {
    const metadataLines = metadata?.split("\n").map(line => line.trim()).filter(line => line.length > 0) ?? [];
    let data: BlogPost = {
        slug,
        category: "other",
        title: "",
        //description: "",
        //created: Date.now()
    };

    for (const line of metadataLines) {
        const [first, ...rest] = line.split(":");
        const key = first.trim();
        let value = rest.join(":").trim();

        // Parse as array
        switch (key) {
            case "title":
                data["title"] = value;
                break;
            case "category":
                data["category"] = value.split(",")[0];
                break;
            case "cover":
                data["cover"] = value;
                break;
            case "description":
                // Convert newlines in value (\n) to actual newlines
                value = value.replace(/\\n/g, "\n");
                data["description"] = value;
                break;
            case "keywords":
                data["keywords"] = value.slice(1, -1).split(",").map((v: string) => v.trim());
                break;
            case "created":
                const date = new Date(value);
                // Check valid date
                if (!isNaN(date.getTime())) {
                    data["created"] = date.getTime();
                }
                break;
            default:
                //console.error(`Unknown metadata key ${key}`);
                break;
        }
    }

    return data;
}


export function groupPostsByCategories (posts: BlogPost[]): Record<string, BlogPost[]> {

    // Group the posts
    const postsGroupedByCategory: Record<string, BlogPost[]> = {};
    for (const post of posts) {
        const category = post.category.toLowerCase();
        if (!postsGroupedByCategory[category]) {
            postsGroupedByCategory[category] = [];
        }

        postsGroupedByCategory[category].push(post);
    }

    // Sort the postsGroupedByCategory by name
    const sortedPostsGroupedByCategory: Record<string, BlogPost[]> = {};
    Object.keys(postsGroupedByCategory).sort().forEach((key) => {
        sortedPostsGroupedByCategory[key] = postsGroupedByCategory[key];
    });

    return sortedPostsGroupedByCategory;
}
