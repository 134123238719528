import { css, html, LitElement, PropertyValues } from "lit";
import { customElement } from "lit/decorators/custom-element.js";
import { property } from "lit/decorators/property.js";
import { sharedStyles } from "../styles/shared";
import "./progress-spinner";

export type Size = "tiny" | "small" | "medium" | "large" | "huge";

@customElement("mb-form-item")
export class BsFormItem extends LitElement {
	static get styles() {
		return [
			sharedStyles,
			css`
				:host {
					position: relative;
					display: inline-flex;
					pointer-events: none;
					--_form-item-color: var(--form-item-color, var(--primary-color-contrast));
					--_form-item-bg: var(--form-item-bg, var(--primary-color));
					--_form-item-border-color: var(--form-item-border-color, var(--shade-normal));

					--_current-form-item-color: var(--_form-item-color);
					--_current-form-item-bg: var(--_form-item-bg);
					--_current-form-item-border-color: var(--_form-item-border-color, var(--shade-normal));

					font-size: var(--form-item-font-size, var(--font-size-m));
					font-family: var(--form-item-font-family, var(--font-family-base));
				}

				::slotted([slot="input"]),
				.input {
					pointer-events: all;
					display: flex;
					align-items: var(--form-item-align-items, center);
					justify-content: var(--form-item-justify-content, center);
					flex-grow: 1;

					accent-color: var(--form-item-accent-color, var(--focus-color-input));
					outline-color: var(--form-item-accent-color, var(--focus-color-input));
					outline-width: var(--form-item-outline-width, 1px);
					appearance: none;

					border-radius: var(--form-item-border-radius, var(--border-radius-l));
					color: var(--_current-form-item-color);
					background: var(--_current-form-item-bg);
					height: var(--_current-form-item-height, var(--form-item-height, 40px));
					padding: var(--form-item-padding-v, 0) var(--form-item-padding-h, var(--space-m));
					position: relative;
					font-size: inherit;
					line-height: inherit;
					font-family: inherit;
					border: var(--form-item-border, 1px solid var(--_current-form-item-border-color));
					transition: var(--form-item-transition, transform 120ms ease-out);
				}

				:host([margin]) {
					margin: 0 0 var(--space-m);
				}

				:host([fab]) .input,
				:host([fab]) ::slotted([slot="input"]) {
					width: var(--form-item-height, 40px);
					aspect-ratio: 1 / 1;
				}

				:host([loading]) ::slotted([slot="input"]),
				:host([loading]) .input {
					pointer-events: none;
				}

				.input[disabled],
				::slotted([slot="input"][disabled]) {
					--_current-form-item-color: var(--shade-dark);
					--_current-form-item-bg: var(--shade-light);
					--_current-form-item-border-color: var(--shade-light);
					--form-item-button-transform-hover: none;
					--form-item-button-transform-active: none;
					//z-index: -1;
					opacity: 1;
					pointer-events: none;
				}

				:host([fill]) {
					display: flex;
					flex-grow: 1;
					width: 100%;
				}

				:host([size="small"]) {
					font-size: var(--form-item-font-size, var(--font-size-s));
					--form-item-padding-h: var(--space-m);
					--form-item-height: 30px;
					--icon-size: 14px;
				}

				:host([size="tiny"]) {
					font-size: var(--form-item-font-size, var(--font-size-xs));
					--icon-size: 12px;
					--form-item-padding-h: var(--space-s);
					--form-item-height: 20px;
				}

				:host([size="large"]) {
					font-size: var(--form-item-font-size, var(--font-size-xm));
					--form-item-height: 50px;
					--form-item-button-padding-h: var(--space-l);
				}

				:host([size="huge"]) {
					font-size: var(--form-item-font-size, var(--font-size-l));
					--form-item-height: 60px;
					--form-item-button-padding-h: var(--space-xl);
				}

				:host([invert]) {
					--_current-form-item-color: var(--_form-item-bg);
					--_current-form-item-bg: var(--_form-item-color);
				}

				:host([flat]) ::slotted([slot="input"]),
				:host([flat]) .input {
					background-color: transparent;
					border: none;
				}

				::slotted([slot="input"]:not([readonly]):active) {
					opacity: var(--form-item-opacity-active, 0.8);
				}

				::slotted(textarea[slot="input"]) {
					resize: vertical;
					min-height: var(--form-item-textarea-min-height, 140px);
					padding: var(--form-item-padding-v, var(--space-m)) var(--form-item-padding-h, var(--space-m));
				}

				::slotted(textarea[slot="input"]),
				::slotted(input[slot="input"]) {
					width: 100%;
				}

				.input:not(textarea):not(input),
				::slotted([slot="input"]:not(textarea):not(input)) {
					cursor: pointer;
					user-select: none;
					-webkit-user-select: none;
				}

				button.input,
				::slotted(button[slot="input"]) {
					gap: var(--form-item-gap, var(--space-xs));
					display: flex;
					flex-direction: var(--form-item-flex-direction, row);
					align-items: var(--form-item-align-items, center);
					justify-content: var(--form-item-justify-content, center);
					padding: var(--form-item-button-padding-v, var(--form-item-padding-v, 0))
						var(--form-item-button-padding-h, var(--form-item-padding-h, var(--space-xm)));
					border-radius: var(--form-item-button-border-radius, var(--form-item-border-radius, 9999px));
					flex-shrink: 0;
					border: var(--form-item-button-border, none);
				}

				/* Buttons should only have a border if they are inverted and not flat  */
				:host([invert]:not([flat])) button.input,
				:host([invert]:not([flat])) ::slotted(button[slot="input"]) {
					border: var(--form-item-border, 1px solid var(--_current-form-item-border-color));
				}

				@media (hover: hover) {
					button.input:not([disabled]):not([readonly]):hover,
					::slotted(button[slot="input"]:not([disabled]):not([readonly]):hover) {
						transform: var(--form-item-button-transform-hover, scale(1.05));
					}
				}

				button.input,
				::slotted(button[slot="input"]) {
					transform: var(--form-item-button-transform);
				}

				button.input:not([disabled]):not([readonly]):active,
				::slotted(button[slot="input"]:not([disabled]):not([readonly]):active) {
					transform: var(--form-item-button-transform-active, scale(0.95));
				}

				.input[disabled],
				::slotted([slot="input"][disabled]),
				::slotted([slot="input"][readonly]) {
					cursor: default;
				}

				.input[disabled],
				::slotted([slot="input"][disabled]) {
					pointer-events: none;
				}

				::slotted(select[slot="input"]) {
					background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23FEFEFE' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
					background-repeat: no-repeat;
					background-position: right 0.6em center;
					background-size: 1em;
					width: 100%;
				}

				::slotted(input[slot="input"][type="color"]) {
					padding: 0;
					cursor: pointer;
					overflow: hidden;
				}

				::slotted(input[slot="input"][type="file"]) {
					cursor: pointer;
					padding-top: 1.1%;
				}

				.input[type="range"],
				::slotted(input[slot="input"][type="range"]) {
					appearance: auto;
					height: 6px;
					background: var(--_current-form-item-bg);
					border: none;
					margin: var(--space-s) 0;
					padding: 0;
				}

				.input[type="checkbox"],
				::slotted(input[slot="input"][type="checkbox"]) {
					appearance: auto;
				}

				:host([invert]) ::slotted([slot="input"]) {
				}

				:host([invert]) ::slotted(select[slot="input"]) {
					background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%234A4A4A' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
				}

				#progress-spinner {
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					color: var(--_current-form-item-color);
					--progress-spinner-size: 1.3em;
					animation: fade-in 1.5s ease-out forwards;
				}

				:host([loading]) .input,
				:host([loading]) ::slotted([slot="input"]) {
					--_current-form-item-color: var(--_current-form-item-bg);
					transition: color 200ms ease-out;
				}

				@keyframes fade-in {
					from {
						opacity: 0;
					}
					to {
						opacity: 1;
					}
				}

				:host([show-spinner="right"]) #progress-spinner {
					left: unset;
					right: var(--form-item-padding-h, var(--space-m));
					transform: translateY(-50%);
				}

				:host([show-spinner="left"]) #progress-spinner {
					left: var(--form-item-padding-h, var(--space-m));
					right: unset;
					transform: translateY(-50%);
				}
			`
		];
	}

	@property({ type: String, reflect: true }) size: Size = "medium";
	@property({ type: Boolean, reflect: true }) flat = false;
	@property({ type: Boolean, reflect: true }) margin = false;
	@property({ type: Boolean, reflect: true }) invert = false;
	@property({ type: Boolean, reflect: true }) fill = false;
	@property({ type: Boolean, reflect: true }) loading = false;
	@property({ type: Boolean, reflect: true }) fab = false;
	@property({ type: Boolean, reflect: true }) autofocus = false;
	@property({ type: String, reflect: true, attribute: "show-spinner" }) showSpinner?: "center" | "left" | "right";

	get $slottedInput(): HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | undefined {
		return (this.shadowRoot?.querySelector("#input") ??
			this.shadowRoot?.querySelector<HTMLSlotElement>('slot[name="input"]')?.assignedElements()[0]) as
			| HTMLInputElement
			| HTMLSelectElement
			| HTMLTextAreaElement
			| undefined;
	}

	get value() {
		return this.$slottedInput?.value ?? "";
	}

	setValue(string: string) {
		if (this.$slottedInput != null) {
			this.$slottedInput.value = string;
		}
	}

	reset() {
		this.setValue("");
	}

	protected firstUpdated(props: PropertyValues) {
		super.firstUpdated(props);
		if (this.autofocus) {
			requestAnimationFrame(() => {
				this.focusInput();
			});
		}
	}

	reportValidity(message: string, resetMs = 3000) {
		this.$slottedInput?.setCustomValidity(message);
		this.$slottedInput?.reportValidity();
		const resetCustomValidity = () => {
			this.$slottedInput?.removeEventListener("input", resetCustomValidity);
			this.$slottedInput?.setCustomValidity("");
		};

		this.$slottedInput?.addEventListener("input", resetCustomValidity, { once: true });
		setTimeout(() => resetCustomValidity(), resetMs);
	}

	focusInput() {
		this.$slottedInput?.focus();
	}

	render() {
		return html`
			<slot name="input"></slot>
			<slot></slot>
			${this.showSpinner || this.loading
				? html` <bs-progress-spinner id="progress-spinner"></bs-progress-spinner> `
				: undefined}
		`;
	}
}
