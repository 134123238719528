import { css, html, LitElement } from "lit";
import { customElement } from "lit/decorators/custom-element.js";
import { sharedStyles } from "../styles/shared";
import "./text";
import "./section";
import {APP_TITLE} from "../config";

const CURRENT_YEAR = (new Date()).getFullYear();

@customElement("mb-footer")
class MbFooter extends LitElement  {
    static get styles() {
        return [sharedStyles, css`
            :host {
                display: block;
                width: 100%;
            }
            
            #footer {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: var(--space-m) 0;
            }
        `];
    }

    render() {
        return html`
            <mb-section>
                <footer id="footer">
                    <mb-text label>© ${CURRENT_YEAR} ${APP_TITLE}</mb-text>
                </footer>
            </mb-section>
        `;
    }
}
