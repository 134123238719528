import {APP_DESCRIPTION, APP_TITLE} from "./config";

export const ROUTES = {
    home: "/",
    category: (category: string) => `/${category}`,
    post: (category: string, id: string) => `/${category}/${id}`,
}

const ROUTE_CHANGE_EVENT = "route-change";

export function goTo(route: string, {replace, silent, data}:{replace?: boolean, silent?: boolean, data?: any} = {}) {
    if (replace) {
        history.replaceState(data ?? {}, "", route);
    } else {
        history.pushState(data ?? {}, "", route);
    }

    if (!silent) {
        dispatchRouteChangeEvent();
    }
}

export function dispatchRouteChangeEvent() {
    window.dispatchEvent(new CustomEvent(ROUTE_CHANGE_EVENT));
}

export function addRouteChangeListener(listener: () => void) {
    window.addEventListener(ROUTE_CHANGE_EVENT, listener);
}

export function isRouteActive(route: string, {exact}: {exact?: boolean} = {}) {
    if (exact) {
        return location.pathname === route;
    }

    return location.pathname.startsWith(route);
}

export function setSEOMetadata ({title, description, keywords, image}: { title: string, description: string; keywords?: string[], image?: string }) {
    document.title = title;
    document.querySelector("meta[name='title']")?.setAttribute("content", title);
    document.querySelector("meta[property='og:title']")?.setAttribute("content", title);
    document.querySelector("meta[name='description']")?.setAttribute("content", description);
    document.querySelector("meta[property='og:description']")?.setAttribute("content", description);
    document.querySelector("meta[name='keywords']")?.setAttribute("content", keywords?.join(", ") ?? "");
    document.querySelector("meta[property='og:image']")?.setAttribute("content", image ?? "");
}

export function setDefaultSEOMetadata () {
    setSEOMetadata({
        title: APP_TITLE,
        description: APP_DESCRIPTION
    });
}
