export const BASE_POSTS_PATH = "/posts";
export const BASE_MEDIA_PATH = "/media";
export const CATEGORIES_FILE = "data/categories.json";

export const MOBILE_BREAKPOINT = 700;

export const IS_LOCAL_HOST = location.hostname === "localhost";
export const APP_EMOJI = "📚";
export const APP_TITLE = "World’s knowledge, visualized.";
export const APP_DESCRIPTION = "Wikipedia turned into mindmaps";
export const APP_DESCRIPTION_MARKDOWN = `Welcome to mapped.wiki, a community project to visualize the most popular wikipedia pages as interactive mind maps. It’s a playful way to explore knowledge and learn about all kinds of related subjects, click by click. 

All Mind Maps are generated with the help of OpenAI and powered by Ideamap and are based on public knowledge on wikipedia pages.

You’re always welcome to give us <a href="https://idea-widget.ideanote.io/?config=eb51d7808a">feedback</a> or <a href="https://ideamap.ai?ref=mapped.wiki">create your own mindmap</a>.`;


export const CATEGORIES = [
    {
        "name": "Agriculture",
        "emoji": "🌾",
        "text": "Here you can see mind maps on Agriculture, touching on topics such as farming practices, soil science, crop production, livestock management, sustainable agriculture, agricultural economics, and much more. Explore the intricacies of agricultural systems and their pivotal role in global food supply and sustainability."
    },
    {
        "name": "Food and Drink",
        "emoji": "🥑",
        "text": "Here you can see mind maps on all things food and drink. These mind maps cover a variety of topics including cuisines from around the world, cooking techniques, types of beverages, nutrition, food science, and much more. Whether you're a culinary expert or a food enthusiast, you'll find detailed and informative mind maps in this category."
    },
    {
        "name": "Art",
        "emoji": "🎨",
        "text": "Here you can see mind maps on various aspects of Art. The collection covers a wide range of topics including different art movements, famous artists, painting techniques, art history, and more. Whether you're interested in the Renaissance or Modern Art, there's something for every art enthusiast here."
    },
    {
        "name": "Architecture",
        "emoji": "🏛️",
        "text": "Here you can see mind maps on various aspects of architecture, including styles, influential architects, historical periods, and iconic structures. The category explores the artistic, technical, and cultural dimensions of architecture."
    },
    {
        "name": "Engineering",
        "emoji": "🛠️",
        "text": "Here you can see mind maps on various topics within the field of engineering. This category includes mind maps on civil, mechanical, electrical, chemical engineering, and more. Each mind map breaks down complex concepts, theories, and practices into simpler, easy-to-understand clusters of information."
    },
    {
        "name": "Technology",
        "emoji": "🔧",
        "text": "Here you can see mind maps on a wide array of technology topics. This category spans innovations, devices, software, algorithms, and more, providing concise visual guides to understand and navigate the complex world of technology."
    },
    {
        "name": "Computing",
        "emoji": "🖥️",
        "text": "Here you can see mind maps on various aspects of computing. These mind maps cover a wide range of topics including software development, hardware mechanisms, internet technologies, artificial intelligence, cybersecurity, data science, and more."
    },
    {
        "name": "Transport",
        "emoji": "🚄",
        "text": "Here you can see mind maps on various aspects of transport. Explore topics such as modes of transportation, infrastructure, vehicle types, sustainable travel solutions, and innovations in transport technology. Dive into detailed breakdowns of air, land, and sea travel, public transit systems, and the future of urban mobility."
    },
    {
        "name": "Geography",
        "emoji": "🌍",
        "text": "Here you can see mind maps on diverse geographical topics, ranging from physical landscapes and climate patterns to human geography and cultural regions. Explore how various elements like mountains, rivers, cities, and population movements shape our world."
    },
    {
        "name": "Places",
        "emoji": "🌍",
        "text": "Here you can see mind maps on a wide variety of places. Explore detailed mind maps covering countries, cities, landmarks, and geographical features from around the world. Discover cultural, historical, and natural aspects of these places through clear, visual representations."
    },
    {
        "name": "History",
        "emoji": "📜",
        "text": "Here you can see mind maps on historical topics ranging from ancient civilizations to modern history. This category encompasses key events, influential figures, cultural shifts, wars, revolutions, and historical eras that have shaped our world."
    },
    {
        "name": "Anthropology",
        "emoji": "🧬",
        "text": "Here you can see mind maps on various Anthropology topics, a comprehensive exploration into the study of humans, their behavior, and societies. Delve into the realms of cultural anthropology, social anthropology, biological anthropology, and more."
    },
    {
        "name": "Leaders",
        "emoji": "👑",
        "text": "Here you can see mind maps on influential leaders from various fields and eras. Discover the intricate paths of their leadership journeys, significant contributions, personal backgrounds, and the impact they've had on society, politics, science, and culture."
    },
    {
        "name": "Nobility",
        "emoji": "👑",
        "text": "Here you can see mind maps on **Nobility**. This category delves into aristocratic lineages, titles of nobility, historical reigns, and the intricate social hierarchies associated with noble families. From the grandeur of monarchies to the subtle distinctions of peerages, explore the roles, privileges, and responsibilities that have defined nobility throughout history and across different cultures."
    },
    {
        "name": "Language",
        "emoji": "🗣️",
        "text": "Here you can see mind maps on various topics related to Language. Discover detailed maps exploring linguistics, language families, grammar rules, and language evolution. From phonetics to semantics, these mind maps break down the complexities of human communication into visual diagrams that are easy to understand."
    },
    {
        "name": "Literature",
        "emoji": "📚",
        "text": "Here you can see mind maps on a diverse array of literature topics. This category encompasses the study of written works across different genres, periods, and cultures, including novels, poems, plays, and essays. Discovering how literary elements such as theme, character, plot, and setting interplay will help deepen your understanding and appreciation of literary arts."
    },
    {
        "name": "Mathematics",
        "emoji": "📐",
        "text": "Here you can see mind maps on various mathematics topics. This category includes visual representations of algebra, geometry, calculus, statistics, number theory, and mathematical logic."
    },
    {
        "name": "Media",
        "emoji": "🎥",
        "text": "Here you can see mind maps on various media-related topics. These cover a broad range of subjects including cinema, television, radio, journalism, digital media, and print media. Explore how different forms of media influence society, culture, and communication."
    },
    {
        "name": "Drama",
        "emoji": "🎭",
        "text": "Here you can see mind maps on various aspects of drama. This category encompasses mind maps that cover the rich history of drama, different genres, famous plays and playwrights, theatrical techniques, and much more. Dive into the world of dramatic arts and explore the interplay of character, plot, and emotion through these visually engaging mind maps."
    },
    {
        "name": "Film",
        "emoji": "🎬",
        "text": "Here you can see mind maps on films, exploring a wide range of topics from movie genres and iconic films to influential directors, actors, and film production techniques. These mind maps break down the complexities of the film industry, allowing you to explore the interconnected elements that make up your favorite movies."
    },
    {
        "name": "Fashion",
        "emoji": "👗",
        "text": "Here you can see mind maps on all aspects of fashion. Explore trends, history, influential designers, types of clothing, textiles, fashion capitals, and much more. Discover how fashion impacts culture, technology, and sustainability in the modern world."
    },
    {
        "name": "Design",
        "emoji": "🎨",
        "text": "Here you can see mind maps on various aspects of design. This category covers a wide range of topics from graphic and web design to interior and industrial design. It includes key principles, influential designers, famous design movements, and practical design methodologies. Each mind map provides a detailed visual breakdown of complex design concepts, making it easier to understand and apply them in real-world contexts."
    },
    {
        "name": "Mythology",
        "emoji": "🧝‍♂️",
        "text": "Here you can see mind maps on the fascinating world of mythology. From ancient Greek gods and goddesses to Norse legends, and mythical creatures across various cultures, these maps visualize intricate stories and connections. Explore the origins, key figures, and cultural impacts of legendary tales that have shaped human imagination and storytelling through centuries."
    },
    {
        "name": "Television",
        "emoji": "📺",
        "text": "Here you can see mind maps on television. Explore the world of TV series, popular shows, historical programming, broadcasting companies, influential figures in TV history, and the evolution of television technology. Each mind map breaks down complex concepts and relationships within the television industry."
    },
    {
        "name": "Music",
        "emoji": "🎵",
        "text": "Here you can see mind maps on various musical topics, including genres, famous artists, music theory, instruments, and the global impact of music. Discover interconnected themes and dive deep into the world of rhythms, melodies, and harmonies."
    },
    {
        "name": "Albums",
        "emoji": "🎶",
        "text": "Here you can see mind maps on various music albums spanning multiple genres, decades, and artists. Each mind map provides detailed information about the album's creation, tracks, themes, critical reception, and historical context."
    },
    {
        "name": "Classical Compositions",
        "emoji": "🎼",
        "text": "Here you can see mind maps on classical compositions. This collection covers a wide range of classical music pieces, from timeless symphonies and operas to sonatas and concertos. Each mind map breaks down the structure, themes, composers, and historical context of the works, offering insights into their creation and significance in the world of classical music."
    },
    {
        "name": "Songs",
        "emoji": "🎵",
        "text": "Here you can see mind maps on various songs spanning different genres, artists, and eras. Explore the fascinating details of your favorite pop hits, rock anthems, timeless classics, and more. Each mind map provides a unique visual breakdown of song lyrics, themes, historical context, and the artistic nuances that make these songs resonate with listeners."
    },
    {
        "name": "Biology",
        "emoji": "🧬",
        "text": "Here you can see mind maps on various fascinating aspects of Biology. Delve into the secrets of cellular processes, genetics, evolution, anatomy, physiology, and ecosystems. Each mind map provides a visual breakdown of complex biological concepts, making them easier to understand and explore. These maps cover everything from the smallest microorganisms to the majestic creatures that inhabit our planet and the intricate networks that sustain all life forms."
    },
    {
        "name": "Medicine",
        "emoji": "🩺",
        "text": "Here you can see mind maps on various topics related to medicine. These include detailed explanations of diseases, treatments, medical procedures, patient care, anatomy, physiology, pharmacology, and innovations in medical research. The category covers a broad range of subjects beneficial for medical students, healthcare professionals, and anyone interested in understanding the human body and healthcare better."
    },
    {
        "name": "Chemistry",
        "emoji": "🧪",
        "text": "Here you can see mind maps on topics related to Chemistry. Explore detailed diagrams covering Organic Chemistry, Inorganic Chemistry, Physical Chemistry, Biochemistry, and Analytical Chemistry. These mind maps make it easy to understand complex chemical reactions, molecular structures, and the periodic table."
    },
    {
        "name": "Materials Science",
        "emoji": "🧪",
        "text": "Here you can see mind maps on topics within Materials Science. Delve into the heart of materials, including properties, classifications, applications, and how they are synthesized and manipulated. Explore key areas like nanomaterials, polymers, ceramics, and more, each with detailed visual breakdowns showcasing how they contribute to technology, industry, and everyday life."
    },
    {
        "name": "Earth Science",
        "emoji": "🌍",
        "text": "Here you can see mind maps on various Earth Science topics. Explore mind maps on geology, meteorology, oceanography, and environmental science, detailing processes, concepts, and natural phenomena affecting our planet."
    },
    {
        "name": "Physics",
        "emoji": "🔬",
        "text": "Here you can see mind maps on the fundamental concepts of physics. This category covers topics ranging from classical mechanics to quantum physics, including electromagnetism, thermodynamics, and relativity."
    },
    {
        "name": "Astronomy",
        "emoji": "🌌",
        "text": "Here you can see mind maps on various topics related to astronomy – from the fundamental concepts of celestial bodies and space exploration to the mysteries of black holes and the lifecycle of stars. These mind maps offer a structured visual breakdown of complex astronomical phenomena, making it easier to understand the vast universe around us."
    },
    {
        "name": "Philosophy",
        "emoji": "🧠",
        "text": "Here you can see mind maps on all the fundamental and deep questions about existence, knowledge, values, reason, mind, and language. This category encompasses topics such as ancient Greek philosophy, ethics, metaphysics, epistemology, logic, political philosophy, and modern philosophy. Dive into the intricate connections and profound questions that have shaped human thought across millennia."
    },
    {
        "name": "Religion",
        "emoji": "🕌",
        "text": "Here you can see mind maps on various religious topics. This category covers the beliefs, practices, rituals, scriptures, histories, and cultural impacts of different religions around the world."
    },
    {
        "name": "Culture",
        "emoji": "🌍",
        "text": "Here you can see mind maps on various facets of human culture. From art and music to language and traditions, explore how different societies express themselves and interact with one another. Understand the impact of cultural norms, values, heritage, and beliefs on shaping individual and collective identities."
    },
    {
        "name": "Sociology",
        "emoji": "👥",
        "text": "Here you can see mind maps on a wide range of Sociology topics. This includes the study of society, social relationships, culture, institutions, and human behavior from various sociological perspectives."
    },
    {
        "name": "People",
        "emoji": "👤",
        "text": "Here you can see mind maps on a wide array of notable people who have shaped history, culture, science, and society. Discover insights into the lives of famous individuals, their contributions, and how they have influenced the world."
    },
    {
        "name": "Psychology",
        "emoji": "🧠",
        "text": "Here you can see mind maps on various aspects of psychology. This category covers topics related to mental processes, behaviors, and the mind. It includes mind maps on psychological theories, mental health conditions, significant figures in psychology, therapeutic techniques, and the cognitive processes underlying human behavior."
    },
    {
        "name": "Education",
        "emoji": "🎓",
        "text": "Here you can see mind maps on education. Discover a wide range of topics ranging from the philosophy of education, educational methodologies, learning theories, and the history of education, to practical aspects such as instructional techniques, curriculum development, and educational technology. These maps visually break down complex educational theories and present information in a digestible format."
    },
    {
        "name": "Economics",
        "emoji": "💰",
        "text": "Here you can see mind maps on various Economics topics. From theories of supply and demand, financial markets, and economic policies to the intricacies of macroeconomics and microeconomics, these mind maps provide a structured and visual way to understand the vast field of economics."
    },
    {
        "name": "Business",
        "emoji": "💼",
        "text": "Here you can see mind maps on various aspects of business, including entrepreneurship, management, marketing, finance, and human resources. These mind maps break down complex business concepts into digestible parts."
    },
    {
        "name": "Law",
        "emoji": "⚖️",
        "text": "Here you can see mind maps on various law-related topics. These include criminal law, civil law, constitutional law, and international law. Each mind map breaks down complex legal concepts into digestible pieces, making it easier to understand statutes, case laws, legal systems, and doctrines."
    },
    {
        "name": "Journalism",
        "emoji": "📰",
        "text": "Here you can see mind maps on the various facets of journalism. Explore topics such as investigative journalism, broadcast journalism, the evolution of news media, journalistic ethics, significant historical events covered by journalists, and the role of journalism in society."
    },
    {
        "name": "Politics",
        "emoji": "🌍",
        "text": "Here you can see mind maps on political structures, theories, ideologies, and influential figures. Topics range from the intricacies of international relations, political economy, electoral systems, to the biographies of world leaders and activists."
    },
    {
        "name": "Government",
        "emoji": "🏛️",
        "text": "Here you can see mind maps on various aspects of government. These mind maps cover topics such as political systems, branches of government, legislative processes, public administration, and political ideologies. Learn about the structure and functions of different types of government, historical events that shaped political landscapes, and key figures in politics."
    },
    {
        "name": "Sports",
        "emoji": "🏅",
        "text": "Here you can see mind maps on various sports topics, encompassing professional leagues, historical moments, legendary athletes, essential rules, and major events such as the Olympics and World Cups."
    },
    {
        "name": "Space",
        "emoji": "🪐",
        "text": "Here you can see mind maps on a variety of topics related to space. These mind maps cover the solar system, galaxies, black holes, space exploration missions, famous astronauts, and much more."
    },
    {
        "name": "Recreation",
        "emoji": "🏖️",
        "text": "Here you can see mind maps on a variety of recreational activities, including sports, hobbies, outdoor adventures, games, and leisure pursuits. Explore how different activities are connected, their benefits, and related subtopics."
    },
    {
        "name": "Games",
        "emoji": "🎮",
        "text": "Here you can see mind maps on various games, ranging from classic board games and card games to the latest in video gaming. This category covers rules, strategies, histories, and more insights."
    },
    {
        "name": "Warfare",
        "emoji": "⚔️",
        "text": "Here you can see mind maps on the vast and multifaceted topic of warfare. These maps cover various types of warfare, historical battles, notable military strategies, technological advancements in weaponry, and key figures in military history."
    },
    {
        "name": "Conflicts",
        "emoji": "⚔️",
        "text": "Here you can see mind maps on various conflicts throughout history and the world. These maps explore pivotal battles, wars, and disputes that have shaped nations, cultures, and societies. From ancient skirmishes to modern conflicts, these visual representations break down key events, notable figures, causes, and consequences."
    },
    {
        "name": "Military",
        "emoji": "🪖",
        "text": "Here you can see mind maps on topics related to military history, strategies, technologies, notable battles, key figures, and important military organizations from around the globe. Each mind map delves into specific aspects, offering a comprehensive overview and detailed insights into the intricate world of military affairs."
    },
    {
        "name": "Weapons",
        "emoji": "🔫",
        "text": "Here you can see mind maps on the fascinating and complex world of weapons. These mind maps cover a vast array of topics including historical weapons from different eras, modern military technology, the design and mechanics of various arms, and their roles in society and warfare. Whether it’s ancient swords and spears, medieval siege engines, early firearms, or contemporary weaponry, these maps provide a detailed and structured insight into how different weapons have influenced human history and their functioning."
    },
    {
        "name": "Equipment",
        "emoji": "⚙️",
        "text": "Here you can see mind maps on various types of equipment. From industrial machines to everyday gadgets, explore how different tools and devices function and their applications in various fields."
    },
    {
        "name": "Buildings",
        "emoji": "🏢",
        "text": "Here you can see mind maps on a variety of buildings, from historical monuments to modern skyscrapers. These mind maps explore the architectural details, significance, and history of these structures."
    },
    {
        "name": "Other",
        "emoji": "🌀",
        "text": "Here you can see mind maps on a variety of diverse and miscellaneous topics that don't fit neatly into other categories. This category encompasses everything from unique hobbies and rare phenomena to innovations and oddities."
    },
    {
        "name": "Conspiracy Theories",
        "emoji": "👽",
        "text": "Here you can see mind maps on conspiratorial topics where science has not yet provided an answer. From JFK to aliens, mysteries and historical cover ups to ghosts and unicorns. Dive into the bizarre with mind maps that branch out each subject so it's easier to understand and explain."
    }
]