import {BlogPost} from "../types/blog-post";
import {extractMetadata, parseMarkdownMetadata} from "./metadata-util";
import {BASE_POSTS_PATH, BASE_MEDIA_PATH, CATEGORIES_FILE} from "../config";
import {Category} from "../types/category";

export interface MarkdownData {markdown: string; data: BlogPost}

let CACHED_CATEGORIES_BLOG_POSTS: Record<string, BlogPost[]> = {};
let CACHED_CATEGORIES: Category[] = [];
let CACHED_MARKDOWN_DATA: {[key: string]: MarkdownData} = {};

export function stopEvent(e: Event) {
    e.preventDefault();
    e.stopPropagation();
}

export function hasMarkdownCacheHit (slug: string): boolean {
    return CACHED_MARKDOWN_DATA[slug] != null;
}

export async function loadMarkdown (slug: string): Promise<MarkdownData | null> {

    // Check for cache hit
    if (hasMarkdownCacheHit(slug)) {
        return CACHED_MARKDOWN_DATA[slug];
    }

    try {
        const result = await fetch(`${BASE_POSTS_PATH}/${slug}.md`);

        // Make sure the result is markdown
        if (!result.headers.get("content-type")?.includes("text/markdown")) {
            return null;
        }

        // Parse the markdown and extract metadata
        const {markdown, metadata} = extractMetadata((await result.text()).trim());
        if (!markdown?.length || metadata == null) {
            return null;
        }

        // Parse the metadata
        const data = parseMarkdownMetadata({metadata, slug});
        if (data == null) {
            return null;
        }

        // Cache the result and return
        const markdownData: MarkdownData = {markdown, data};
        CACHED_MARKDOWN_DATA[slug] = markdownData;
        return markdownData;
    } catch (err) {
        console.error("Error parsing markdown", err);
        return null;
    }
}

export async function getAllPosts (category: string): Promise<BlogPost[]> {
    const cachedPosts = CACHED_CATEGORIES_BLOG_POSTS[category];
    if (cachedPosts?.length) {
        return cachedPosts;
    }

    try {
        const result = await fetch(`data/${category}.json`);
        const data = (await result.json() as BlogPost[]).sort((a, b) => a.title.localeCompare(b.title));
        CACHED_CATEGORIES_BLOG_POSTS[category] = data;
        return data;
    } catch (err) {
        console.error("Error parsing data", err);
        return [];
    }
}

export async function getAllCategories (): Promise<Category[]> {
    if (CACHED_CATEGORIES.length) {
        return CACHED_CATEGORIES;
    }

    try {
        const result = await fetch(CATEGORIES_FILE);
        const data = (await result.json() as Category[]);
        CACHED_CATEGORIES = data;
        return data;
    } catch (err) {
        console.error("Error parsing data", err);
        return [];
    }
}

export function promisifyAnimation (animation: Animation): Promise<void> {
    return new Promise(resolve => {
        animation.onfinish = () => resolve();
    });
}

export function getCoverUrl (slug: string): string {
    return getImageUrl(slug, "webp");
}

export function getImageUrl (id: string, format: string = "webp"): string {
    return `${BASE_MEDIA_PATH}/${id}.${format}`;
}

export function getRandomNumber (min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getRandomWidth () {
    return `${getRandomNumber(0.2, 0.8) * 100}%`;
}

export function sortRandomByWeight<T>(arr: {item: T; weight: number}[]): T[] {
    const copy = [...arr];
    copy.sort((a, b) => (b.weight + getRandomNumber(0.1, 0.2)) - (a.weight + getRandomNumber(0.1, 0.2)));
    return copy.map(({item}) => item);
}

export function openCta() {
    (window as any).umami?.track?.('cta.click');
    const url = "https://ideamap.ai?ref=mapped.wiki";
    window.open(url, "_blank");
}

export function openWidget () {
    (window as any).umami.track('feedback.open');
    const $script = document.createElement("script");
    $script.innerHTML = `
 window.ideanoteSettings = {
  config: "eb51d7808a",
  state: "open"
 };
 (function(src){
  var a=document.createElement("script");
  a.async=!0,a.src=src,document.getElementsByTagName("head")[0].appendChild(a)
 })("https://static.ideanote.io/widget/widget.js");
    `;

    document.head.appendChild($script);
}