import {css, html, LitElement, PropertyValues} from "lit";
import { customElement } from "lit/decorators/custom-element.js";
import { sharedStyles } from "./styles/shared";
import {
    addRouteChangeListener,
    dispatchRouteChangeEvent,
    goTo,
    ROUTES,
    setDefaultSEOMetadata,
} from "./router";
import "./components/footer";
import "./components/navbar";
import {property} from "lit/decorators/property.js";
import {when} from "lit/directives/when.js";
import {CATEGORIES} from "./config";

setDefaultSEOMetadata();

@customElement("mb-app")
class MbApp extends LitElement  {
    static get styles() {
        return [sharedStyles, css`
            :host {
                display: flex;
                flex-direction: column;
            }
            
            #page {
                flex-grow: 1;
                display: block;
                padding:  var(--space-xl) 0;
                min-height: 100vh;
            }
            
        `];
    }

    @property({type: Boolean, reflect: true}) screenshot = false;

    protected firstUpdated(props: PropertyValues) {
        super.firstUpdated(props);

        window.addEventListener("popstate", dispatchRouteChangeEvent);
        addRouteChangeListener(() => this.requestUpdate());

        if (location.search.includes("screenshot")) {
            this.screenshot = true;
        }
    }

    renderPage () {
        // Example: /my-category
        if (location.pathname.match(/^\/([^/]+)$/)) {
            const category = location.pathname.split(`/`).pop()?.trim();
            if (category?.length) {
                import("./pages/category-page");
                return html`<mb-category-page category="${decodeURIComponent(category)}"></mb-category-page>`;
            }
        }

        // Example: /my-category/my-post-slug
        if (location.pathname.match(/^\/([^/]+)\/([^/]+)$/)) {
            const slug = location.pathname.split(`/`).pop()?.trim();
            if (slug?.length) {
                import("./pages/blog-post-page");
                return html`<mb-blog-post-page ?screenshot="${this.screenshot}" slug="${slug}"></my-blog-post-page>`;
            }
        }

        goTo(ROUTES.home, {replace: true, silent: true});

        import("./pages/home-page");
        return html`<mb-home-page></mb-home-page>`;
    }

    render() {
        return html`
            <div id="page">
                ${this.renderPage()}
            </div>
            ${when(!this.screenshot, () => html`
                <mb-navbar role="navigation"></mb-navbar>
            `)}
        `;
    }
}
