import { css } from "lit";

export const sharedStyles = css`
	* {
		box-sizing: border-box;
		-webkit-tap-highlight-color: transparent;
	}
    
    h1, h2, h3, h4, h5, h6 {
        font-family: var(--title-font-family);
    }
    
    h1 {
        font-size: var(--font-size-xxxl);
    }
    
    h2 {
        font-size: var(--font-size-xxl);
    }
    
    h3 {
        font-size: var(--font-size-xl);
    }
    
    h4 {
        font-size: var(--font-size-l);
    }
    
     h5 {
        font-size: var(--font-size-m);
    }
    
    hr {
        width: 100%;
        background: var(--shade-light);
        height: 1px;
        margin: 0;
        border-radius: var(--border-radius-s);
        border: none;
    }
    
    a {
        color: var(--link-color);
    }
    
`;
