import { css, html, LitElement } from "lit";
import { customElement } from "lit/decorators/custom-element.js";
import { property } from "lit/decorators/property.js";
import { sharedStyles } from "../styles/shared";
import {Size} from "../types/size";

@customElement("mb-text")
class MbText extends LitElement {
	@property({ reflect: true }) size: Size = "medium";
	@property({ type: Boolean, reflect: true }) margin = false;
	@property({ type: Boolean, reflect: true }) label = false;

	static get styles() {
		return [
			sharedStyles,
			css`
				:host {
					display: block;
					font-size: var(--text-font-size, var(--font-size-m));
					font-weight: var(--text-font-weight, inherit);
					line-height: var(--text-line-height, 1.3);
					font-family: var(--text-font-family, inherit);
					word-wrap: break-word;
				}

				:host([size="large"]) {
					font-size: var(--text-font-size, var(--font-size-l));
					line-height: var(--text-line-height, 1.5);
				}

				:host([size="small"]) {
					font-size: var(--text-font-size, var(--font-size-s));
				}

				:host([size="tiny"]) {
					font-size: var(--text-font-size, var(--font-size-xs));
				}

				:host([size="huge"]) {
					font-size: var(--text-font-size, var(--font-size-xl));
					line-height: var(--text-line-height, 1.5);
				}

				:host([margin]) {
					margin: var(--text-margin, 0 0 0.5em);
				}

				:host([label]) {
					color: var(--shade-dark);
					line-height: var(--text-label-line-height, 1);
				}
			`
		];
	}

	render() {
		return html`<slot></slot>`;
	}
}
