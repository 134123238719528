import { css, html, LitElement } from "lit";
import { customElement } from "lit/decorators/custom-element.js";
import { sharedStyles } from "../styles/shared";
import {APP_EMOJI, APP_TITLE} from "../config";
import "../components/seo/seo-link";
import "../components/text";
import "../components/form-item";
import {openCta} from "../util/util";

@customElement("mb-navbar")
class MbNavbar extends LitElement  {
    static get styles() {
        return [sharedStyles, css`
            :host {
                position: fixed;
                top: var(--space-s);
                left: var(--space-m);
                width: calc(100% - var(--space-m) * 2);
                z-index: 123456789;
            }
            
            #content {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-between;
                height: 50px;
            }
            
            #cta {
                background: var(--primary-normal);
                color: var(--primary-contrast);
                
            }
            
            #link {
                cursor: pointer;
            }
            
            #link-extension {
                color: var(--shade-light);
            }
            
            #left, #right {
                padding: 0 var(--space-m);
                display: flex;
                align-items: center;
                gap: var(--space-s);
            }
        `];
    }

    render() {
        return html`
                <mb-card id="card" >
                    <div id="content">
                        <div id="left">
                            <mb-seo-link href="/">
                                <mb-text id="link">${APP_EMOJI} mapped<span id="link-extension">.wiki</span></mb-text>
                            </mb-seo-link>
                        </div>
                        <div id="right">
                                <mb-form-item id="cta" size="small" @click="${() => openCta()}">
                                    <button slot="input">Create Mindmap️</button>
                                </mb-form-item>
                        </div>
                    </div>
                </mb-card>
        `;
    }
}
