import { css, html, LitElement } from "lit";
import { customElement } from "lit/decorators/custom-element.js";
import { sharedStyles } from "../styles/shared";
import {property} from "lit/decorators.js";

@customElement("mb-section")
class MbSection extends LitElement  {
    static get styles() {
        return [sharedStyles, css`
            :host {
                padding: var(--space-m);
                display: block;
            }
            
            #container {
                display: block;
                width: 100%;
                max-width: 1000px;
                margin: 0 auto;
            }
            
            :host([width="thin"]) #container {
                max-width: 720px;
            }
            
            :host([width="wide"]) #container {
                max-width: 1200px;
            }
        `];
    }

    @property({type: String, reflect: true}) width: "normal" | "thin" | "wide" = "normal";

    render() {
        return html`
            <div id="container">
                <slot></slot>
            </div>
        `;
    }
}
